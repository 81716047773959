import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    animation: {
      classOut: '_close',
      timeoutOut: 1300,
    },
    closerText: '',
    onFormSuccess: function () {
      const submitEvent = new Event('formSubmit');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);
      this.content.querySelector('[data-modal-success]').classList.remove('_awaiting-animation');
    },
  });
  return false;
});
