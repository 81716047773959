import { onDomChanges, onDomReady } from '../../components/dynamic/observer';

function init() {
  document.querySelectorAll('[data-floating-field] input, [data-floating-field] textarea').forEach((input) => {
    const field = input.closest('[data-floating-field]');
    if (field.dataset.initialized) {
      return;
    }
    field.dataset.initialized = 'true';
    input.addEventListener('keyup', () => {
      if (input.value) {
        field.classList.add('_filled');
      } else {
        field.classList.remove('_filled');
      }
    });

    document.addEventListener('success', () => {
      field.classList.remove('_filled');
    });
  });
}

onDomReady(() => init());
onDomChanges(() => init());