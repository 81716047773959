import {onDomReady} from "../../components/dynamic/observer";
import BigImageObject from "./objects/BigImageObject";
import ImageObject from "./objects/ImageObject";
import HeroImageObject from "./objects/HeroImageObject";

class Parallax {
    offsetY = 0;
    lastOffsetY = 0;

    constructor() {
        this.collect();
        this.eventListeners();
        this.update();
    }

    collect() {
        this.collectImages();
        this.collectBigImages();
        this.getHeroImage();
    }

    collectImages() {
        this.imageObjects = [];
        Array.from(document.querySelectorAll('[data-parallax-picture]'))
          .forEach((container) => {
              this.imageObjects.push(new ImageObject(container));
          });
    }

    collectBigImages() {
        this.bigImageObjects = [];
        Array.from(document.querySelectorAll('[data-parallax-big-image-container]'))
          .forEach((container) => {
              this.bigImageObjects.push(new BigImageObject(container));
          });
    }

    getHeroImage() {
        this.heroImage = document.querySelector('[data-hero-picture]');
        if (this.heroImage) {
            this.heroImageObject = new HeroImageObject(this.heroImage);
        }
    }

    update() {
        if (this.lastOffsetY !== this.offsetY) {

            this.imageObjects.forEach((imageObject) => {
                imageObject.update();
            });


            this.bigImageObjects.forEach((bigImageObject) => {
                bigImageObject.update();
            });

            if (this.heroImageObject) {
                this.heroImageObject.update(this.lastOffsetY);
            }

            this.lastOffsetY = this.offsetY;
        }

        window.requestAnimationFrame(this.update.bind(this));
    }

    eventListeners() {
        const instance = this;
        window.addEventListener('scroll', (event) => {
            instance.offsetY = window.pageYOffset;
        });
    }
}

onDomReady(() => {
    const parallax = new Parallax();
});
