class HeroImageObject {
  constructor(selector) {
    this.picture = selector;
    this.startOpacity = 1;

    this.update();
  }

  computedRatio(offset) {
    this.ratio = (offset / (window.innerHeight / 100)) / 100;
  }

  computedStyles() {
    this.opacity = this.startOpacity - this.ratio;
  }

  setStyles() {
    this.picture.style.opacity = `${this.opacity}`;
  }

  update(offset) {
    this.computedRatio(offset);
    this.computedStyles();
    this.setStyles();
  }
}

export default HeroImageObject;