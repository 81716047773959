import {onDomReady} from "../../components/dynamic/observer";
import Swiper, {Autoplay, EffectCreative} from "swiper";

onDomReady(() => {
    const sliderEl = document.querySelector('[data-cooperation-slider]');
    if (sliderEl) {
        const slider = new Swiper(sliderEl, {
            modules: [EffectCreative, Autoplay],
            loop: true,
            effect: 'creative',
            creativeEffect: {
                prev: {
                    translate: [0, 0, -1],
                    opacity: 0,
                },
                current: {
                    translate: [0, 0, 0],
                },
                next: {
                    translate: [0, 0, -1],
                    opacity: 0,
                },
            },
        });

        setInterval(() => {
            slider.slideNext(300);
        }, 100);
    }
});