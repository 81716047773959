class ImageObject {
  constructor(container) {
    this.picture = container;
    this.image = this.picture.querySelector('img');
    this.direction = this.picture.dataset.parallaxPicture;
    this.translateLenght = this.picture.offsetHeight / 3;

    this.update();
  }

  computedOffset() {
    const bounds = this.picture.getBoundingClientRect();
    const pictureHeight = this.picture.offsetHeight;
    const activeAreaHeight = window.innerHeight + pictureHeight;

    if (bounds.top > window.innerHeight) {
      this.ratio = 0;
    } else if (bounds.top < window.innerHeight && bounds.bottom > 0) {
      this.ratio = ((activeAreaHeight - (bounds.top + pictureHeight)) / (activeAreaHeight / 100)) / 100;
    } else {
      this.ratio = 1;
    }

    if (this.direction === 'up') {
      this.ratio = this.ratio * -1;
    }
  }

  computedStyles() {
    this.offset = this.translateLenght * this.ratio;
  }

  setStyles() {
    this.image.style.transform = `translate3d(0, ${this.offset}px, 0)`;
  }

  update() {
    this.computedOffset();
    this.computedStyles();
    this.setStyles();
  }
}

export default ImageObject;