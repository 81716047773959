import { throttle } from 'lodash';
import { onDomReady } from '../../components/dynamic/observer';
import { scrollToTarget } from '../../components/scroll/scroll';

class Header {
  constructor(selector) {
    this.header = selector;
    this.headerInner = this.header.querySelector('[data-header-inner]');
    this.hamburger = this.header.querySelector('[data-hamburger]');
    this.dropMenu = this.header.querySelector('[data-header-drop]');

    this.navLinks = Array.from(this.dropMenu.querySelectorAll('[data-nav-link]'));

    this.heroHeight = 0;
    this.hero = document.querySelector('[data-hero]');
    if (this.hero) {
      this.heroHeight = this.hero.offsetHeight;
    }

    this.bind();
    this.eventListeners();
  }

  bind() {
    this.bindHamburger();
    this.bindNavLinks();
  }

  bindHamburger() {
    this.hamburger.addEventListener('click', throttle((e) => {
      e.preventDefault();
      if (this.hamburger.classList.contains('_opened')) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    }, 1300));
  }

  bindNavLinks() {
    this.navLinks.forEach((navLink) => {
      navLink.addEventListener('click', (e) => {
        e.preventDefault();

        this.closeMenu();

        setTimeout(() => {
          let target = null;
          if (navLink.dataset.selector) {
            target = document.querySelector(navLink.dataset.selector);
          } else {
            target = document.querySelector(navLink.getAttribute('href'));
          }
          const offset = parseInt(navLink.dataset.offset, 10) || 0;
          if (target) {
            scrollToTarget(target, offset);
          }
        }, 1000);
      });
    });
  }

  openMenu() {
    this.hamburger.classList.add('_opened');
    this.dropMenu.classList.add('_opened');
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  }

  closeMenu() {
    this.hamburger.classList.add('_closing');
    this.hamburger.classList.remove('_opened');
    this.dropMenu.classList.remove('_opened');
    document.body.style.overflow = '';
    document.body.style.height = '';

    setTimeout(() => {
      this.hamburger.classList.remove('_closing');
    }, 800);
  }

  eventListeners() {
    document.addEventListener('scroll', () => {
      this.scrollTop = window.pageYOffset;
      this.handleHeader();
      this.lastScrollTop = this.scrollTop;
    });
  }

  handleHeader() {
    this.stickyHandler();
    this.visibleHandler();
    this.transitionHandler();
  }

  stickyHandler() {
    if (this.scrollTop > this.headerInner.offsetHeight) {
      this.header.classList.add('_sticky');
    } else {
      this.header.classList.remove('_sticky');
    }
  }

  visibleHandler() {
    if (this.scrollTop > this.heroHeight + this.headerInner.offsetHeight) {
      if (this.scrollTop < this.lastScrollTop) {
        this.header.classList.add('_visible');
      } else {
        this.header.classList.remove('_visible');
      }
    } else {
      this.header.classList.remove('_visible');
    }
  }

  transitionHandler() {
    if (this.scrollTop > this.heroHeight / 2) {
      this.headerInner.style.transition = 'transform .4s var(--default-transition-function)';
    } else {
      this.headerInner.style.transition = '';
    }
  }
}
onDomReady(() => {
  const el = document.querySelector('[data-header]');
  if (el) {
    const header = new Header(el);
  }
});