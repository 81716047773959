import { throttle } from 'lodash';
import { Swiper, EffectCreative, Navigation } from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

class ReviewsSlider {
  constructor(selector) {
    this.container = selector;
    this.slider = this.container.querySelector('[data-reviews-slider]');
    this.list = this.container.querySelector('[data-reviews-slider-list]');
    this.nextEl = this.container.querySelector('[data-reviews-slider-next]');
    this.counterEl = this.container.querySelector('[data-reviews-slider-fraction]');
    this.counterSlider = undefined;

    this.init();
    this.bind();
    this.eventListeners();
  }

  init() {
    this.initSlides();
    this.initLoop();
    this.addNextClass();
    this.setIndexes();
    this.initCounterSlider();
    this.setSlidesHeight();
  }

  initSlides() {
    this.slides = Array.from(this.slider.querySelectorAll('[data-reviews-slide]'));
  }

  initCounterSlider() {
    if (this.counterEl) {
      this.counterSlider = new Swiper(this.counterEl, {
        allowTouchMove: false,
        loop: true,
        direction: 'vertical',
        preventInteractionOnTransition: true,
      });
    }
  }

  prev() {
    const activeSlide = this.slides.filter((slide) => slide.classList.contains('_active'))[0];
    const activeIndex = this.slides.indexOf(activeSlide);

    this.slides[activeIndex].classList.add('_out');
    setTimeout(() => {
      this.slides[activeIndex].classList.remove('_out');
    }, 500);
    this.removeActive();

    if (activeIndex !== 0) {
      this.slides[activeIndex - 1].classList.add('_active');
    } else {
      this.slides[this.slides.length - 1].classList.add('_active');
    }

    document.dispatchEvent(new CustomEvent('slideChanged'));
  }

  next() {
    const activeSlide = this.slides.filter((slide) => slide.classList.contains('_active'))[0];
    const activeIndex = this.slides.indexOf(activeSlide);

    this.slides[activeIndex].classList.add('_out');
    setTimeout(() => {
      this.slides[activeIndex].classList.remove('_out');
    }, 500);
    this.removeActive();

    if (activeIndex !== this.slides.length - 1) {
      this.slides[activeIndex + 1].classList.add('_active');
    } else {
      this.slides[0].classList.add('_active');
    }

    document.dispatchEvent(new CustomEvent('slideChanged'));
  }

  slideTo(slideIndex) {
    const activeSlide = this.slides.filter((slide) => slide.classList.contains('_active'))[0];
    const activeIndex = this.slides.indexOf(activeSlide);

    if (this.slides[slideIndex].classList.contains('_next')) {
      this.slides[activeIndex].style.transform = '';
      this.slides[activeIndex].classList.add('_out');
      setTimeout(() => {
        this.slides[activeIndex].classList.remove('_out');
      }, 500);

      this.removeActive();

      if (activeIndex !== this.slides.length - 1) {
        this.slides[activeIndex + 1].classList.add('_active');
      } else {
        this.slides[0].classList.add('_active');
      }
    } else {
      this.slides[activeIndex].style.zIndex = '';
      this.slides[activeIndex].style.transitionDuration = '0s';

      if (slideIndex === 0) {
        this.slides[activeIndex].style.transform = '';
        this.slides[activeIndex + 1].style.transform = '';

        this.removeActive();

        this.slides[activeIndex + 1].classList.add('_out');
        setTimeout(() => {
          this.slides[activeIndex + 1].classList.remove('_out');
        }, 500);

        this.slides[slideIndex].classList.add('_active');
      } else if (slideIndex === 1) {
        this.slides[activeIndex].style.transform = '';
        this.slides[0].style.transform = '';

        this.removeActive();

        this.slides[0].classList.add('_out');
        setTimeout(() => {
          this.slides[0].classList.remove('_out');
        }, 500);

        this.slides[slideIndex].classList.add('_active');
      } else {
        this.slides[activeIndex].style.transform = '';
        this.slides[activeIndex + 1].style.transform = '';

        this.removeActive();

        this.slides[activeIndex + 1].classList.add('_out');
        setTimeout(() => {
          this.slides[activeIndex + 1].classList.remove('_out');
        }, 500);

        this.slides[slideIndex].classList.add('_active');
      }
      this.slides[activeIndex].style.transitionDuration = '';
    }
    document.dispatchEvent(new CustomEvent('slideChanged'));
  }

  bind() {
    this.bindArrows();
    this.bindSwipes();
    this.bindHovers();
  }

  bindArrows() {
    this.bindNext();
  }

  bindNext() {
    this.nextEl.addEventListener('click', throttle(() => {
      this.next();
    }, 800));
  }

  removeActive() {
    this.slides.map((slide) => slide.classList.remove('_active'));
  }

  removeNext() {
    this.slides.map((slide) => slide.classList.remove('_next'));
  }

  addNextClass() {
    const activeSlide = this.slides.filter((slide) => slide.classList.contains('_active'))[0];
    const activeIndex = this.slides.indexOf(activeSlide);

    if (activeIndex + 1 !== this.slides.length) {
      this.slides[activeIndex + 1].classList.add('_next');
    } else {
      this.slides[0].classList.add('_next');
    }
  }

  bindSwipes() {
    let startX;
    this.slider.addEventListener('mousedown', (e) => {
      this.slider.style.cursor = 'grabbing';
      startX = e.pageX;
    });
    this.slider.addEventListener('mouseup', throttle((e) => {
      this.slider.style.cursor = 'grab';
      if (e.pageX !== startX) {
        if (e.pageX > startX) {
          // this.prev();
        } else {
          this.next();
        }
      }
    }, 800));
  }

  initLoop() {
    if (this.slides.length > 3) {
      return;
    }
    // this.duplicateSlides();
    this.initSlides();
  }

  duplicateSlides() {
    let slideIndex = 0;
    while (this.list.children.length < 6) {
      const newSlide = this.slides[slideIndex].cloneNode(true);
      newSlide.classList.remove('_active');
      this.list.append(newSlide);
      slideIndex++;
    }
  }

  setIndexes() {
    const activeSlide = this.slides.filter((slide) => slide.classList.contains('_active'))[0];
    const activeIndex = this.slides.indexOf(activeSlide);

    this.slides.forEach((slide, index) => {
      let zIndex = (this.slides.length - index) + activeIndex;
      if (zIndex > this.slides.length) {
        zIndex = activeIndex - index;
      }
      slide.style.zIndex = `${zIndex}`;
    });
  }

  bindHovers() {
    this.slides.forEach((slide, index) => {
      slide.addEventListener('mouseenter', () => {
        if (!slide.classList.contains('_active')) {
          this.slider.style.cursor = 'pointer';

          if (slide.classList.contains('_next')) {
            if (index === 0) {
              this.slides[this.slides.length - 1].style.transform = 'translateX(-57px)';
            } else {
              this.slides[index - 1].style.transform = 'translateX(-57px)';
            }
          } else {
            if (index === 0) {
              this.slides[this.slides.length - 1].style.transform = 'translateX(0)';
              this.slides[this.slides.length - 2].style.transform = 'translateX(-57px)';
            } else if (index === 1) {
              this.slides[0].style.transform = 'translateX(0)';
              this.slides[this.slides.length - 1].style.transform = 'translateX(-57px)';
            } else {
              this.slides[index - 1].style.transform = 'translateX(0)';
              this.slides[index - 2].style.transform = 'translateX(-57px)';
            }
          }
        }
      });

      slide.addEventListener('mouseleave', () => {
        if (!slide.classList.contains('_active')) {
          this.slider.style.cursor = '';

          if (slide.classList.contains('_next')) {
            if (index === 0) {
              this.slides[this.slides.length - 1].style.transform = '';
            } else {
              this.slides[index - 1].style.transform = '';
            }
          } else {
            if (index === 0) {
              this.slides[this.slides.length - 1].style.transform = '';
              this.slides[this.slides.length - 2].style.transform = '';
            } else if (index === 1) {
              this.slides[0].style.transform = '';
              this.slides[this.slides.length - 1].style.transform = '';
            } else {
              this.slides[index - 1].style.transform = '';
              this.slides[index - 2].style.transform = '';
            }
          }
        }
      });

      slide.addEventListener('mouseup', () => {
        if (!slide.classList.contains('_active')) {
          this.slideTo(index);
        }
      });
    });
  }

  setCounter() {
    if (this.counterSlider) {
      this.counterSlider.slideTo(this.activeIndex);
    }
  }

  setActiveIndex() {
    this.slides.forEach((slide) => {
      if (slide.classList.contains('_active')) {
        this.activeIndex = slide.dataset.reviewsSlide;
      }
    });
  }

  setSlidesHeight() {
    let maxHeight = 0;
    this.slides.forEach((slide) => {
      if (slide.offsetHeight > maxHeight) {
        maxHeight = slide.offsetHeight;
      }
    });
    this.slides.map((slide) => slide.style.height = `${maxHeight}px`);
    this.list.style.height = `${maxHeight}px`;
  }

  eventListeners() {
    document.addEventListener('slideChanged', () => {
      this.removeNext();
      this.addNextClass();
      setTimeout(() => {
        this.setIndexes();
      }, 500);

      this.setActiveIndex();
      this.setCounter();
    });

    window.addEventListener('resize', () => {
      this.setSlidesHeight();
    });
  }
}

onDomReady(() => {
  const el = document.querySelector('[data-reviews-slider-container]');
  if (el) {
    if (window.innerWidth >= 1200) {
      const slider = new ReviewsSlider(el);
    } else {
      const sliderEl = el.querySelector('[data-reviews-slider]');
      const list = el.querySelector('[data-reviews-slider-list]');
      const slides = Array.from(el.querySelectorAll('[data-reviews-slide]'));
      const next = el.querySelector('[data-reviews-slider-next]');

      sliderEl.classList.add('swiper');
      list.classList.add('swiper-wrapper');
      slides.map((slide) => slide.classList.add('swiper-slide'));

      const slider = new Swiper(sliderEl, {
        modules: [EffectCreative, Navigation],
        loop: true,
        navigation: {
          nextEl: next,
        },
        effect: 'creative',
        creativeEffect: {
          prev: {
            translate: ['-100%', 0, 1],
          },
          next: {
            translate: [0, 0, 0],
          },
        },
        on: {
          init(swiper) {
            swiper.slides.forEach((slide) => {
              slide.style.height = `${slide.parentElement.offsetHeight}px`;
            });
          },
        },
      });

      const counterEl = el.querySelector('[data-reviews-slider-fraction]');
      const counterSlider = new Swiper(counterEl, {
        allowTouchMove: false,
        loop: true,
        direction: 'vertical',
        preventInteractionOnTransition: true,
      });

      slider.on('realIndexChange', () => {
        counterSlider.slideTo(slider.realIndex + 1, 400);
      });
    }
  }
});