class BigImageObject {
  constructor(container) {
    this.container = container;
    this.picture = this.container.querySelector('[data-parallax-big-image]');
    this.image = this.picture.querySelector('img');

    const rootStyles = getComputedStyle(document.documentElement);
    this.startWidth = rootStyles.getPropertyValue('--max-row-width');

    // this.startScale = this.startWidth < window.innerWidth ? this.startWidth / window.innerWidth : 0.8;
    this.startScale = 1;
    this.startImageScale = 1.2;

    this.update();
  }

  computedRatio() {
    const bounds = this.container.getBoundingClientRect();

    if (window.innerWidth > 1199) {
      this.endScale = 1.7;

      if (bounds.top < window.innerHeight && bounds.top > 0) {
        this.imageRatio = (window.innerHeight - bounds.top) / (window.innerHeight / 100) / 100;
      }

      if (bounds.top > 0) {
        this.ratio = 0;
      } else if (bounds.top < 0 && bounds.bottom > window.innerHeight / 2) {
        this.ratio = (bounds.top * -1) / ((this.container.offsetHeight - window.innerHeight / 2) / 100) / 100;
      } else {
        this.ratio = 1;
      }
    } else {
      this.endScale = 1.3;
      if (bounds.top > window.innerHeight / 2) {
        this.ratio = 0;
      } else if (bounds.top < window.innerHeight / 2 && bounds.bottom > (window.innerHeight / 4) * 3) {
        this.ratio = ((bounds.top - window.innerHeight / 2) * -1) / ((this.container.offsetHeight - window.innerHeight / 4) / 100) / 100;
      } else {
        this.ratio = 1;
      }
    }
  }

  computedStyles() {
    this.scale = this.startScale + ((this.endScale - this.startScale) * this.ratio);
    this.imageScale = this.startImageScale - ((this.imageRatio / 10) * 2);
  }

  setStyles() {
    this.picture.style.transform = `scale(${this.scale})`;
    this.image.style.transform = `scale(${this.imageScale})`;
  }

  update() {
    this.computedRatio();
    this.computedStyles();
    this.setStyles();
  }
}

export default BigImageObject;