import { Swiper, Navigation } from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  document.querySelectorAll('[data-service-slider]')
    .forEach((item) => {
      const sliderNavLeft = item.querySelector('[data-service-slider-left]');
      const sliderNavRight = item.querySelector('[data-service-slider-right]');

      const slider = new Swiper(item, {
        modules: [Navigation],
        loop: true,
        speed: 800,
        draggable: true,
        preventInteractionOnTransition: true,
        navigation: {
          nextEl: sliderNavRight,
          prevEl: sliderNavLeft,
        },
      });

      const counterEl = item.querySelector('[data-service-slider-fraction]');
      const counterSlider = new Swiper(counterEl, {
        allowTouchMove: false,
        loop: true,
        direction: 'vertical',
        preventInteractionOnTransition: true,
      });

      slider.on('realIndexChange', () => {
        counterSlider.slideTo(slider.realIndex + 1, 800);
      });
    });
});